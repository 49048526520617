<template>
  <div class="flex grow flex-col justify-center">
    <div class="flex flex-col items-center text-center">
      <h1 class="text-2xl font-semibold tracking-wide md:font-bold lg:text-3xl">Ihr Termin, Ihr Wohlbefinden!</h1>
      <p class="mt-2 max-w-lg text-lg leading-8 text-gray-600">
        Finden Sie den perfekten Zeitpunkt für Ihre Beratung in nur wenigen Klicks.
      </p>
    </div>
    <nav aria-label="Progress" class="mx-auto mt-4 max-w-sm md:mt-6 lg:mt-8">
      <ol role="list" class="overflow-hidden">
        <li
          v-for="(step, stepIdx) in steps"
          :key="step.name"
          :class="[stepIdx !== steps.length - 1 ? 'pb-6' : '', 'relative']"
        >
          <div
            v-if="stepIdx !== steps.length - 1"
            class="absolute left-5 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          ></div>
          <div class="group relative flex items-start pt-2">
            <span class="flex h-9 items-center" aria-hidden="true">
              <span
                class="relative z-[1] flex h-10 w-10 items-center justify-center rounded-full border-2 border-meevo-blue-400 bg-white"
              >
                <span
                  class="flex h-7 w-7 items-center justify-center rounded-full bg-meevo-blue-500 text-sm font-bold text-white"
                  >{{ stepIdx + 1 }}</span
                >
              </span>
            </span>
            <span class="ml-4 flex min-w-0 flex-col">
              <span class="font-bold text-meevo-blue-500">{{ step.name }}</span>
              <span class="text-gray-600">{{ step.description }}</span>
            </span>
          </div>
        </li>
      </ol>
    </nav>
    <div class="sticky bottom-12 mt-2 flex flex-col items-center md:mt-4 lg:mt-6">
      <NuxtLink to="/appointment/new/1" class="z-[1]">
        <FormBaseButton class="m-4 px-8" :size="ButtonSize.xl">Jetzt Termin buchen</FormBaseButton>
      </NuxtLink>
      <div class="absolute inset-0 bg-white opacity-50" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ButtonSize } from '~/types/Button'

const { reset, steps } = useStep()

reset()
</script>
